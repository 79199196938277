/**********************************
VARIABLES
***********************************/

$color-prim: #03A9F4;
$color-prim-lighter: #4FC3F7;
$color-prim-darker: #0277BD;

$color-accent: #1DE9B6;
$color-accent-lighter: #64FFDA;
$color-accent-darker: #00BFA5;



$color-text-dark-prim: rgba(0, 0, 0, .87);
$color-text-dark-sec: rgba(0, 0, 0, .54);
$color-text-dark-hint: rgba(0, 0, 0, .38);

$color-text-light-prim: rgba(255, 255, 255, 1);
$color-text-light-sec: rgba(255, 255, 255, .7);
$color-text-light-hint: rgba(255, 255, 255, .5);



$bp-800: 'only screen and (min-width: 800px)';
$bp-950: 'only screen and (min-width: 950px)';


%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}


/**********************************
GENERAL
***********************************/

* {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  color: $color-text-dark-prim;
  background: $color-prim;
  
}

h2 {
  color: $color-text-light-prim;
  text-align: center;
  font-size: 2.5em;
  font-weight: normal;
  margin: 3em auto 1.2em auto;
}

p {
  font-size: 1.1em;
  line-height: 1.9;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
  color: $color-accent;
  outline: none;
}

img {
  max-width: 100%;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style-position: inside;
  list-style-type: circle;
}

li {
  padding: .3em 0;
}



/**********************************
HOME PAGE
***********************************/

.social-icons {
  position: fixed;
  bottom: 10px;
  right: -300px;
  z-index: 9999;
  transition: right 0.4s ease-out;

  @media #{$bp-800} {
    right: -100px;
    bottom: 20px;
  }
  
  
  li {
    list-style-type: none;
    display: inline-block;
    padding: .3em;
    @media #{$bp-800} {
      display: block;
    }
    span {
      display: inline-block;
      border-radius: 100%;
      text-align: center;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      background: $color-accent;
      transition: all 0.2s ease-out;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, .15);

      i {
        color: $color-text-light-prim;
        width: 45px;
        height: 45px;
        line-height: 45px;
      }

      @media #{$bp-800} {
        &:hover {
          background: $color-accent-lighter;
          color: $color-text-light-prim;
          transform: scale(1.1, 1.1);
          i {
            color: $color-text-light-prim;
          }
          &:before {
            position: absolute;
            right: 50px;
            line-height: 20px;
            top: 10px;
            padding: 3px 8px;
            border-radius: 2px;
            background: #fff;
            color: $color-text-dark-prim;
          }
          &[title='GitHub'] {
            &:before {
              content: 'GitHub';
            } 
          }
          &[title='LinkedIn'] {
            &:before {
              content: 'LinkedIn';
            } 
          }
          &[title='Resume (PDF)'] {
            &:before {
              content: 'Resume (PDF)';
              width: 100px;
            } 
          }
          &[title='Email'] {
            &:before {
              content: 'claytonlocher@gmail.com';
            } 
          }
        }
      }
    }
  }
}

.content-container {
  width: 100%;
  padding: 0 3%;
  margin-bottom: 2em;
  clear: both;
  @media #{$bp-800} {
    padding: 0 10%;
  }
}

.section-title {
  color: $color-text-light-sec;
  font-weight: bold;

  &:after {
    content: '';
    display: inline-block;
    position: relative;
    vertical-align: middle;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background: $color-text-light-sec;
  }

  &:before {
    right: .4em;
  }

  &:after {
    left: .2em;
    vertical-align: baseline;
  }
}

.top-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  background: none;
  
  

  #main-logo {
    max-height: 12em;
    display: inline-block;
    max-width: 80%;
    text-align: center;
    @media #{$bp-800} {
      max-width: 65%;
    }
  }

  #tagline {
    font-family: 'Mukta Vaani';
    font-family: 'Lato';
    color: $color-text-light-prim;
    margin-top: 1.5em;
    max-width: 90%;
    font-size: 1.5em;
    @media #{$bp-800} {
      font-size: 2.8em;
    }
    .offset {
      display: inline-block;
      margin: 0 .2em;
      font-weight: bold;
      transform: rotate(-6deg) translate(0, -6px);
      border-bottom: 2px dotted $color-text-light-prim;
    }
  }

  .down-arrow {
    position: absolute;
    display: inline-block;
    bottom: 10px;
    text-align: center;
    width: 100%;
    color: $color-text-light-sec;
  }

}

.card {
  @extend %clearfix;
  background: #fafafa;
  color: $color-text-dark-prim;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
  padding: 5%;
  clear: both;
  border-radius: 3px;
  margin-bottom: 10%;
  @media #{$bp-800} {
    margin-bottom: 5%;
  }
}

.project-header {
  width: 100%;
  flex: 1 0 auto;

  h3 a {
    color: $color-prim-darker;
    font-size: 2em;
    margin-bottom: .5em;
  }

}

.project-timeline {
  color: $color-text-dark-sec;
  border-top: 1px solid $color-prim-lighter;
  border-bottom: 1px solid $color-prim-lighter;
  padding: .3em 0 .3em 0;
  vertical-align: 30%;
}

.col {
  float: left;
  width: 100%;
  @media #{$bp-800} {
    width: 55%;
  }

  &:first-of-type {
    @media #{$bp-800} {
      width: 45%;
      padding-right: 2.5%;
    }
  }

  &:nth-of-type(2n) {
    float: left;
    @media #{$bp-800} {
      width: 55%;
      padding-left: 2.5%;
    }
  }

  a {
    & img {
      margin-top: 10%;
    }
    &:first-of-type img {
      margin-top: 1em;
    }
    &:nth-of-type(2n + 2) img {
      margin-right: 5%;
    }
  }


}


.website-link {
  display: inline-block;
  margin: .5em 0;
  padding: .7em 1em;
  background: $color-accent;
  color: $color-text-light-prim !important;
  font-size: 1.1em;
  font-weight: bold;
  border-radius: 2px;
  vertical-align: 30%;
  transition: all 0.2s ease-out;
  @media #{$bp-800} {
    &:hover {
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .2);
      span {
      padding-left: .6em;
      }
    }
  }
  span {
    display: inline-block;
    padding-left: .2em;
    transition: padding 0.2s ease-out;
  }


  i {
    padding-left: .2em;
    vertical-align: middle;

  }
}

.tech-stack {
  display: block;
  margin: 1em 0 .5em;

  ul {
    padding: .5em 0;
  }

  ul li {
    display: inline-block;
    border: 2px solid $color-accent;
    color: $color-accent;
    padding: .3em .6em;
    margin: .2em .2em .4em 0;
    border-radius: 2px;
    font-size: 1.1em;
  }

}

.screenshot {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
  text-align: center;
  float: left;
  transition: all 0.2s ease-out;

  @media #{$bp-800} {
    &:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, .30);
      transform: scale(1.02, 1.02);
    }
  }

  
  &.mobile {
    width: 47.5%;
  }

}

.about {
  margin: 0 auto;
  width: 100%;
  @media #{$bp-800} {
    width: 85%;
  }
  @media #{$bp-950} {
    width: 70%;
  }
  p:nth-of-type(n + 2) {
    clear: both;
  }
}


#headshot {
  width: 200px;
  border-radius: 100%;
  // border: 2px solid #eee;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .25);
  margin: 0 auto;
  display: block;
  @media #{$bp-950} {
    float: left;
    margin: 0 2em 2em 0;
  }

}

footer {
  margin: 3em auto 0;
  padding-bottom: 1em;
  color: $color-text-light-sec;
  width: 96%;
  text-align: center;
  @media #{$bp-800} {
    width: 70%;
    text-align: left;
  }
  #copyright {
    display: block;
    @media #{$bp-800} {
      float: right;
    }
  }
}

